import React, { useEffect } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import Constants from "../../utils/constants";

const PayPalCheckoutWrapper = ({ amount, onSuccess, description }) => {
  const [{ options }, dispatch] = usePayPalScriptReducer();
  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        intent: "capture",
      },
    });
  }, []);

  return (
    <PayPalButtons
      disabled={amount <= 0 || isNaN(amount)}
      fundingSource="paypal"
      style={{
        layout: "vertical",
        shape: "pill",
        color: "gold",
        tagline: false,
      }}
      forceReRender={[amount]}
      createOrder={(data, actions) => {
        return actions.order.create({
          intent: "CAPTURE",
          application_context: {
            shipping_preference: "NO_SHIPPING",
          },
          purchase_units: [
            {
              amount: {
                currency_code: Constants.CURRENCY,
                value: amount,
                breakdown: {
                  item_total: {
                    currency_code: Constants.CURRENCY,
                    value: amount,
                  },
                },
              },
              items: [
                {
                  name: description,
                  quantity: "1",
                  category: "DIGITAL_GOODS",
                  unit_amount: {
                    currency_code: Constants.CURRENCY,
                    value: amount,
                  },
                },
              ],
            },
          ],
        });
      }}
      onApprove={(data, actions) => {
        if (actions?.order) {
          return actions?.order?.capture().then((details) => {
            onSuccess(details);
          });
        } else {
          return new Promise((resolve, reject) => resolve());
        }
      }}
      onError={(err) => {}}
    />
  );
};

export default PayPalCheckoutWrapper;
