import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import Session from "../store/session";
import Confirm from "./Confirm";
import useScreenSize, { BreakPoint } from "../hooks/useScreenSize";

function Header() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const size = useScreenSize();
  const space = 80;

  const handleLogout = () => {
    Session.logout();
    window.location.href = "/login";
  };

  return (
    <>
      <Confirm
        open={openConfirmDialog}
        onNo={() => setOpenConfirmDialog(false)}
        onYes={() => {
          setOpenConfirmDialog(false);
          handleLogout();
        }}
        title="Want to logout?"
        body="You'll be logged out from the application."
      />
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar
            disableGutters
            style={{
              justifyContent:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "flex-start"
                  : "space-between",
            }}
          >
            <Box
              sx={{
                display:
                  size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                    ? "flex"
                    : "none",
              }}
            >
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
                xs={{ ml: "auto" }}
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: "block", md: "none" },
                }}
              >
                <MenuItem
                  key={"home"}
                  onClick={() => {
                    navigate("/");
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">Home</Typography>
                </MenuItem>
                <MenuItem
                  key={"profile"}
                  onClick={() => {
                    navigate("/profile");
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">Profile</Typography>
                </MenuItem>
                <MenuItem
                  key={"plans"}
                  onClick={() => {
                    navigate("/plans");
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">Plans</Typography>
                </MenuItem>
                <MenuItem
                  key={"contact"}
                  onClick={() => {
                    navigate("/contact");
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">Contact</Typography>
                </MenuItem>
                <MenuItem
                  key={"logout"}
                  onClick={() => setOpenConfirmDialog(true)}
                >
                  <Typography textAlign="center">Logout</Typography>
                </MenuItem>
              </Menu>
            </Box>

            <Box
              sx={{
                minWidth: space,
                maxWidth: space,
                display:
                  size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                    ? "none"
                    : "flex",
              }}
            ></Box>
            <Box
              sx={{
                display:
                  size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                    ? "none"
                    : "flex",
              }}
            >
              <Button
                key="home"
                onClick={() => {
                  navigate("/");
                  handleCloseNavMenu();
                }}
                sx={{
                  my: 2,
                  mx: 2,
                  color: "white",
                  display: "block",
                }}
              >
                <span>Home</span>
              </Button>
              <Button
                key="profile"
                onClick={() => {
                  navigate("/profile");
                  handleCloseNavMenu();
                }}
                sx={{ my: 2, mx: 2, color: "white", display: "block" }}
              >
                <span>Profile</span>
              </Button>
              <Button
                key="plans"
                onClick={() => {
                  navigate("/plans");
                  handleCloseNavMenu();
                }}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                <span>Plans</span>
              </Button>
              <Button
                key="contact"
                onClick={() => {
                  navigate("/contact");
                  handleCloseNavMenu();
                }}
                sx={{ my: 2, mx: 2, color: "white", display: "block" }}
              >
                <span>Contact</span>
              </Button>
            </Box>
            <Box
              sx={{
                minWidth: space,
                maxWidth: space,
                display:
                  size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                    ? "none"
                    : "flex",
              }}
            >
              <Button
                key="logout"
                onClick={() => setOpenConfirmDialog(true)}
                sx={{ my: 2, color: "white", display: "block" }}
              >
                <span>Logout</span>
              </Button>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
}

export default Header;
