import React, { useEffect } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

const PayPalSubscriptionWrapper = ({ onSuccess, paypalPlanId }) => {
  const [{ options }, dispatch] = usePayPalScriptReducer();
  useEffect(() => {
    dispatch({
      type: "resetOptions",
      value: {
        ...options,
        intent: "subscription",
        vault: true,
      },
    });
  }, []);

  return (
    <PayPalButtons
      fundingSource="paypal"
      style={{
        layout: "vertical",
        shape: "pill",
        color: "gold",
        label: "subscribe",
        tagline: false,
      }}
      forceReRender={[]}
      createSubscription={(data, actions) => {
        return actions.subscription.create({
          plan_id: paypalPlanId || "",
        });
      }}
      onApprove={(data, actions) => {
        return new Promise((resolve, reject) => {
          if (data) {
            onSuccess(data);
            resolve();
          } else {
            reject();
          }
        });
      }}
      onError={(err) => {}}
    />
  );
};

export default PayPalSubscriptionWrapper;
