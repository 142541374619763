export const CATEGORY_TYPES = {
  IMAGES: "images",
  VIDEOS: "videos",
};
export const PACKAGES = {
  FREE: "free",
  TRIAL: "trial",
  MONTHLY: "monthly",
  YEARLY: "yearly",
  LIFETIME: "lifetime",
};
export const PAYMENT_METHODS = {
  PAYPAL: "paypal",
  STRIPE: "stripe",
};

export const USER_TYPES = {
  USER: "user",
  ADMIN: "admin",
};

export const PAYMENT_TYPES = {
  PAYMENT: "payment",
  SUBSCRIPTION: "subscription",
};

export const SUBSCRIPTION_STATUS = {
  ACTIVE: "active",
  CANCELLED: "cancelled",
  ONETIME: "payment",
};

export const SLIDESHOW_FOR = {
  NONE: "none",
  SINGLE: "single",
  MULTIPLE: "multiple",
};
