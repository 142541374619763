import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import Constants from "./utils/constants";

const theme = createTheme({
  palette: {
    primary: {
      main: "#000",
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ThemeProvider theme={theme}>
    <PayPalScriptProvider
      options={{
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID || "",
        components: "buttons",
        currency: Constants.CURRENCY,
        "disable-funding": "paylater",
      }}
    >
      <App />
    </PayPalScriptProvider>
  </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
