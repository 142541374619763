import React from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box,
  IconButton,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import useScreenSize, { BreakPoint } from "../hooks/useScreenSize";

function HeaderPublic() {
  const navigate = useNavigate();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => setAnchorElNav(event.currentTarget);
  const handleCloseNavMenu = () => setAnchorElNav(null);
  const size = useScreenSize();
  const space = 180;

  return (
    <AppBar position="static">
      <Container maxWidth="xl">
        <Toolbar
          disableGutters
          style={{
            justifyContent:
              size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                ? "flex-start"
                : "space-between",
          }}
        >
          {/* Mobile menu */}
          <Box
            sx={{
              display:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "flex"
                  : "none",
            }}
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem
                key={"home"}
                onClick={() => {
                  navigate("/");
                  handleCloseNavMenu();
                }}
              >
                <Typography textAlign="center">Home</Typography>
              </MenuItem>
              <MenuItem
                key={"contact"}
                onClick={() => {
                  navigate("/contact");
                  handleCloseNavMenu();
                }}
              >
                <Typography textAlign="center">Contact</Typography>
              </MenuItem>
              <MenuItem
                key={"plans"}
                onClick={() => {
                  navigate("/plans");
                  handleCloseNavMenu();
                }}
              >
                <Typography textAlign="center">Plans</Typography>
              </MenuItem>
              <MenuItem
                key={"login"}
                onClick={() => {
                  navigate("/login");
                  handleCloseNavMenu();
                }}
              >
                <Typography textAlign="center">Login</Typography>
              </MenuItem>
              <MenuItem
                key={"signup"}
                onClick={() => {
                  navigate("/signup");
                  handleCloseNavMenu();
                }}
              >
                <Typography textAlign="center">Signup</Typography>
              </MenuItem>
            </Menu>
          </Box>

          {/* Desktop view */}
          <Box
            sx={{
              minWidth: space,
              maxWidth: space,
              display:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "none"
                  : "flex",
            }}
          ></Box>
          <Box
            sx={{
              display:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "none"
                  : "flex",
            }}
          >
            <Button
              key="home"
              onClick={() => {
                navigate("/");
                handleCloseNavMenu();
              }}
              sx={{ my: 2, mx: 2, color: "white", display: "block" }}
            >
              <span>Home</span>
            </Button>
            <Button
              key="contact"
              onClick={() => {
                navigate("/contact");
                handleCloseNavMenu();
              }}
              sx={{ my: 2, mx: 2, color: "white", display: "block" }}
            >
              <span>Contact</span>
            </Button>
            <Button
              key="plans"
              onClick={() => {
                navigate("/plans");
                handleCloseNavMenu();
              }}
              sx={{ my: 2, mx: 2, color: "white", display: "block" }}
            >
              <span>Plans</span>
            </Button>
          </Box>
          <Box
            sx={{
              minWidth: space,
              maxWidth: space,
              display:
                size.screen === BreakPoint.xs || size.screen === BreakPoint.s
                  ? "none"
                  : "flex",
            }}
          >
            <Button
              key="login"
              onClick={() => {
                navigate("/login");
                handleCloseNavMenu();
              }}
              sx={{ my: 2, mx: 2, color: "white", display: "block" }}
            >
              <span>Login</span>
            </Button>
            <Button
              key="signup"
              onClick={() => {
                navigate("/signup");
                handleCloseNavMenu();
              }}
              sx={{ my: 2, mx: 2, color: "white", display: "block" }}
            >
              <span>Signup</span>
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default HeaderPublic;
