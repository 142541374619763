import React from "react";
import { Snackbar, Alert, Slide, AlertTitle } from "@mui/material";
import { CheckCircleOutline } from "@mui/icons-material";

function ToastSuccess({ open, onClose, title = "Success!", body }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={onClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      TransitionComponent={Slide}
    >
      <Alert
        severity="success"
        variant="standard"
        icon={<CheckCircleOutline fontSize="large" />}
        sx={{ width: "100%" }}
      >
        <AlertTitle>{title}</AlertTitle>
        {body}
      </Alert>
    </Snackbar>
  );
}

export default ToastSuccess;
