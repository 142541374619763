import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function LoginFirst({ open, onClose }) {
  const navigate = useNavigate();
  return (
    <div>
      <Dialog open={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <DialogContent>
          <Typography variant="div" component="div">
            Please login or signup before buying any plan. This will make sure
            to attach the plan you will buy with your account.
          </Typography>
        </DialogContent>
        <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
          <Button variant="contained" color="grey" onClick={onClose}>
            Okay
          </Button>
          <Button onClick={() => navigate("/login")} variant="contained">
            Login Now
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
