import React, { useEffect, useState } from "react";
import useStore from "../../store/store";
import { Link, useNavigate } from "react-router-dom";
import { Grid, Typography } from "@mui/material";
import settingService from "../../services/setting";
import paymentService from "../../services/payment";
import userService from "../../services/user";
import useScreenSize from "../../hooks/useScreenSize";
import { Button } from "../../controls";
import {
  AutoAwesomeOutlined,
  AutoFixHighOutlined,
  AutoFixNormalOutlined,
  CancelOutlined,
  FreeBreakfastOutlined,
  HighlightOffOutlined,
  WarningOutlined,
} from "@mui/icons-material";
import { PACKAGES, SUBSCRIPTION_STATUS } from "../../utils/enums";
import MakePayment from "./MakePayment";
import utils from "../../utils/utils";
import LoginFirst from "./LoginFirst";
import Confirm from "../../components/Confirm";
import Constants from "../../utils/constants";
import ExpiryMessage from "../../components/ExpiryMessage";
import moment from "moment";

const PlanCard = ({
  id,
  title,
  price,
  sub,
  icon,
  onClick,
  onCancel,
  onStartTrial,
}) => {
  const { user } = useStore((state) => state);
  let showCancelButton =
    user?.package === id &&
    id !== PACKAGES?.FREE &&
    user?.package !== PACKAGES?.LIFETIME;
  let showBuyButton =
    user?.package !== id && user?.package !== PACKAGES?.LIFETIME;
  let showTrialButton = id === PACKAGES.FREE;
  let disableTrialButton = user?.trialTaken;
  let disabled = user?.package === id;

  return (
    <div className="plan-item pointer">
      <Typography
        component="h3"
        variant="h3"
        style={{ marginTop: 20 }}
        color="grey.500"
      >
        {title}
      </Typography>
      <Typography
        component="h2"
        variant="h2"
        style={{ marginTop: 20 }}
        color={disabled ? "grey.500" : "primary"}
      >
        ${price}
      </Typography>
      <Typography
        component="small"
        variant="p"
        style={{ color: "#aeaeae", marginTop: 20 }}
      >
        {sub}
      </Typography>
      {showTrialButton && (
        <>
          <Button
            text="Start Trial"
            size="small"
            style={{
              maxWidth: 200,
              minHeight: 40,
              marginTop: 20,
              marginBottom: 20,
            }}
            icon={<FreeBreakfastOutlined />}
            onClick={disableTrialButton ? () => null : onStartTrial}
            disabled={disableTrialButton}
          />
          {disableTrialButton && <small>You've already taken free trial</small>}
        </>
      )}
      {showCancelButton && (
        <Button
          text="Cancel"
          size="small"
          style={{
            maxWidth: 200,
            minHeight: 40,
            marginTop: 20,
            marginBottom: 20,
          }}
          color="error"
          icon={<HighlightOffOutlined />}
          onClick={onCancel}
        />
      )}
      {showBuyButton && id !== PACKAGES.FREE && (
        <Button
          text={`Choose ${title}`}
          size="small"
          style={{
            maxWidth: 200,
            minHeight: 40,
            marginTop: 20,
            marginBottom: 20,
          }}
          icon={icon}
          onClick={onClick}
        />
      )}
    </div>
  );
};

function Plans() {
  const {
    token,
    setErrorMessage,
    setSuccessMessage,
    setIsLoading,
    setUser,
    isExpired,
    setIsExpired,
    user,
  } = useStore((state) => state);
  const [pricing, setPricing] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState("");
  const [showPaymentPopup, setShowPaymentPopup] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [showConfirmStartTrial, setShowConfirmStartTrial] = useState(false);

  useEffect(() => {
    reload();
    reloadSession();
  }, []);

  const reload = () => {
    settingService.get().then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        return;
      }
      setPricing(result.data?.pricing);
    });
  };

  const reloadSession = async () => {
    if (!token || !user) return;
    userService.reload(token).then((r) => {
      if (r.data) {
        setUser(r.data);
        setIsExpired(r.data?.isExpired);
      }
    });
  };

  const handleCancel = async () => {
    if (!token || !user) return;
    setIsLoading(true);
    paymentService.cancelMySubscription(token).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setSuccessMessage("Your subscription has been cancelled successfully!");
      reloadSession();
      setShowConfirm(false);
      setIsLoading(false);
    });
  };

  const handleStartFreeTrial = () => {
    if (!token || !user) return;
    setIsLoading(true);
    userService
      .startTrial(token, { date: moment().format() })
      .then((result) => {
        if (result.error) {
          setErrorMessage(result.error);
          setShowConfirmStartTrial(false);
          setIsLoading(false);
          return;
        }
        setShowConfirmStartTrial(false);
        setIsLoading(false);
        reloadSession();
      });
  };

  return (
    <>
      <LoginFirst
        open={showLoginPopup}
        onClose={() => {
          setSelectedPackage("");
          setShowLoginPopup(false);
        }}
      />
      <MakePayment
        open={(pricing && selectedPackage && showPaymentPopup) || false}
        plan={selectedPackage}
        price={pricing?.[selectedPackage] || 0}
        onClose={() => {
          setSelectedPackage("");
          setShowPaymentPopup(false);
        }}
        onSuccess={() => {
          reloadSession();
          setSelectedPackage("");
          setShowPaymentPopup(false);
        }}
        pricing={pricing}
      />
      <Confirm
        open={showConfirm}
        onNo={() => setShowConfirm(false)}
        onYes={handleCancel}
        body="Are you sure you want to cancel your package?"
      />
      <Confirm
        open={showConfirmStartTrial}
        onNo={() => setShowConfirmStartTrial(false)}
        onYes={handleStartFreeTrial}
        body="Are you sure you want to start free trial?"
      />
      <Grid container spacing={2} sx={{ p: 5, pt: 3 }}>
        <Grid item xs={12}>
          <Typography variant="h5" component="h5">
            Our plans and pricing
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="p" component="p">
            Enjoy the best benefits of {process.env.REACT_APP_NAME} with a paid
            subscription.
          </Typography>
        </Grid>
        {token && user && (
          <Grid item xs={12}>
            <Typography
              variant="div"
              component="div"
              style={{ display: "flex", alignItems: "center" }}
            >
              Your current package:&nbsp;{" "}
              <strong>{utils.toCamelCase(user?.package)}</strong>
            </Typography>
          </Grid>
        )}
        {isExpired && (
          <Grid item xs={12}>
            <ExpiryMessage showLink={false} />
          </Grid>
        )}
      </Grid>
      <Grid container spacing={2} sx={{ p: 5, pt: 3 }}>
        <Grid
          item
          xs={12}
          md={3}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <PlanCard
            id={PACKAGES.FREE}
            price={0}
            title={utils.toCamelCase(PACKAGES.FREE)}
            sub={`This is a free trial package. You can start your trial period by selecting this package. Free trial will expire in ${Constants.PRICING.trial} days.`}
            icon={<AutoFixNormalOutlined />}
            onStartTrial={
              token
                ? () => {
                    setShowConfirmStartTrial(true);
                  }
                : () => setShowLoginPopup(true)
            }
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <PlanCard
            id={PACKAGES.MONTHLY}
            price={pricing?.monthly}
            title={utils.toCamelCase(PACKAGES.MONTHLY)}
            sub="This will create a subscription and will charge every month. You will not be able to use your account after your subscription is expired."
            icon={<AutoFixNormalOutlined />}
            onClick={
              token
                ? () => {
                    setSelectedPackage(PACKAGES.MONTHLY);
                    setShowPaymentPopup(true);
                  }
                : () => setShowLoginPopup(true)
            }
            onCancel={() => setShowConfirm(true)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <PlanCard
            id={PACKAGES.YEARLY}
            price={pricing?.yearly}
            title={utils.toCamelCase(PACKAGES.YEARLY)}
            sub="This will create a subscription and will charge every year. You will not be able to use your account after your subscription is expired."
            icon={<AutoAwesomeOutlined />}
            onClick={
              token
                ? () => {
                    setSelectedPackage(PACKAGES.YEARLY);
                    setShowPaymentPopup(true);
                  }
                : () => setShowLoginPopup(true)
            }
            onCancel={() => setShowConfirm(true)}
          />
        </Grid>
        <Grid
          item
          xs={12}
          md={3}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <PlanCard
            id={PACKAGES.LIFETIME}
            price={pricing?.lifetime}
            title={utils.toCamelCase(PACKAGES.LIFETIME)}
            sub="This is one-time payment. You will be charged only once and your account will kept active lifetime."
            icon={<AutoFixHighOutlined />}
            onClick={
              token
                ? () => {
                    setSelectedPackage(PACKAGES.LIFETIME);
                    setShowPaymentPopup(true);
                  }
                : () => setShowLoginPopup(true)
            }
            onCancel={() => setShowConfirm(true)}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default Plans;
