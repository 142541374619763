import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useState } from "react";
import { Button } from "../../controls";
import useStore from "../../store/store";
import paymentService from "../../services/payment";
import {
  PACKAGES,
  PAYMENT_METHODS,
  PAYMENT_TYPES,
  SUBSCRIPTION_STATUS,
} from "../../utils/enums";
import moment from "moment";

function StripeCheckout({ plan, pricing, onSuccess, title }) {
  const { user, token, setSuccessMessage, setErrorMessage, setIsLoading } =
    useStore((state) => state);
  const stripe = useStripe();
  const elements = useElements();

  const handleSavePaymentStripe = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) return;
    const ce = elements.getElement(CardElement);
    if (!ce) return;

    setIsLoading(true);
    const paymentMethod = await stripe?.createPaymentMethod({
      type: "card",
      card: ce,
      billing_details: {
        name: user?.name,
        email: user?.email,
      },
    });

    if (plan === PACKAGES.MONTHLY || plan === PACKAGES.YEARLY) {
      setIsLoading(true);
      paymentService
        .createStripeSubscription(token, {
          plan: plan,
          paymentMethod: paymentMethod?.paymentMethod?.id,
        })
        .then(async (result) => {
          if (result?.error) {
            setErrorMessage(result?.error);
            setIsLoading(false);
            return;
          }

          const subscriptionId = result?.data?.subscriptionId;
          const clientSecret = result?.data?.clientSecret;
          const confirmPayment = await stripe?.confirmCardPayment(clientSecret);

          if (confirmPayment?.error) {
            setErrorMessage(confirmPayment.error.message);
            setIsLoading(false);
            return;
          }

          paymentService
            .createSubscription(token, {
              method: PAYMENT_METHODS.STRIPE,
              plan: plan,
              amount: pricing?.[plan],
              type: PAYMENT_TYPES.SUBSCRIPTION,
              status: SUBSCRIPTION_STATUS.ACTIVE,
              subscriptionId: subscriptionId,
              baseSubscriptionId: subscriptionId,
              details: paymentMethod,
              date: moment().format(),
            })
            .then((result) => {
              if (result.error) {
                setErrorMessage(result.error);
                setIsLoading(false);
                return;
              }

              setSuccessMessage("Payment completed successfully!");
              setIsLoading(false);
              onSuccess();
            });
        });
    } else if (plan === PACKAGES.LIFETIME) {
      setIsLoading(true);
      paymentService
        .createStripePayment(token, {
          plan: plan,
          paymentMethod: paymentMethod?.paymentMethod?.id,
        })
        .then(async (result) => {
          if (result?.error) {
            setErrorMessage(result?.error);
            setIsLoading(false);
            return;
          }

          const clientSecret = result?.data?.clientSecret;
          const confirmPayment = await stripe?.confirmCardPayment(clientSecret);

          if (confirmPayment?.error) {
            setErrorMessage(confirmPayment.error.message);
            setIsLoading(false);
            return;
          }

          paymentService
            .createPayment(token, {
              method: PAYMENT_METHODS.STRIPE,
              plan: plan,
              amount: pricing?.[plan],
              type: PAYMENT_TYPES.PAYMENT,
              status: SUBSCRIPTION_STATUS.ONETIME,
              subscriptionId: "",
              baseSubscriptionId: "",
              details: paymentMethod,
              date: moment().format(),
            })
            .then((result) => {
              if (result.error) {
                setErrorMessage(result.error);
                setIsLoading(false);
                return;
              }

              setSuccessMessage("Payment completed successfully!");
              setIsLoading(false);
              onSuccess();
            });
        });
    }
  };

  const cardStyle = {
    hidePostalCode: true,
    style: {
      base: {
        border: "1px solid red",
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        fontFamily: "Arial, sans-serif",
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  return (
    <form onSubmit={handleSavePaymentStripe}>
      <CardElement options={cardStyle} />
      <Button text={title} style={{ marginTop: 20 }} type="submit" />
    </form>
  );
}

export default StripeCheckout;
