import React, { useEffect, useState } from "react";
import useStore from "../../store/store";
import { useNavigate } from "react-router-dom";
import { Box, Typography } from "@mui/material";
import settingService from "../../services/setting";
import categoryService from "../../services/category";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Constants from "../../utils/constants";
import userService from "../../services/user";
import { Button } from "../../controls";
import ExpiryMessage from "../../components/ExpiryMessage";
import useScreenSize, { BreakPoint } from "../../hooks/useScreenSize";

const CategoryItem = ({ category }) => {
  const navigate = useNavigate();
  const { isExpired } = useStore((state) => state);
  return (
    <div
      className="category-item pointer"
      onClick={() => navigate(`/category/${category?._id}`)}
    >
      <img src={category?.thumbnail} alt={category?.title} />
      <p className="title font-segeo">{category?.title}</p>
    </div>
  );
};

function Home() {
  const navigate = useNavigate();
  const size = useScreenSize();
  const [images, setImages] = useState([]);
  const [slideDuration, setSlideDuration] = useState(Constants.DURATION);
  const [categories, setCategories] = useState([]);
  const { setErrorMessage, isExpired, setUser, token, user, setIsExpired } =
    useStore((state) => state);

  useEffect(() => {
    reload();
    reloadSession();
  }, []);

  const reload = () => {
    settingService.get().then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        return;
      }
      setImages(
        result.data?.covers?.map((k) => ({
          original: k?.url,
          thumbnail: k?.url,
        }))
      );
      setSlideDuration(result?.data?.slider || Constants.DURATION);
    });

    setCategories([]);
    categoryService.getAll({ keyword: "" }).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        return;
      }

      setCategories(result.data);
    });
  };

  const reloadSession = async () => {
    if (!token) return;
    userService.reload(token).then((r) => {
      if (r.data) {
        setUser(r.data);
        setIsExpired(r.data.isExpired);
      }
    });
  };

  let btnWidth = 100;

  return (
    <>
      <div style={{ position: "relative" }}>
        <div className="home">
          <ImageGallery
            items={images}
            showThumbnails={false}
            showBullets={false}
            showIndex={false}
            showFullscreenButton={false}
            showPlayButton={false}
            showNav={false}
            infinite={true}
            lazyLoad={false}
            autoPlay={true}
            slideDuration={500}
            swipingTransitionDuration={500}
            slideInterval={slideDuration * 1000}
          />
        </div>
        {size.screen === BreakPoint.l || size.screen === BreakPoint.xl ? (
          <div
            style={{
              position: "absolute",
              top: 150,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              width: "100%",
            }}
          >
            <Typography
              variant="h4"
              component="h4"
              className="font-perpetua"
              sx={{ fontWeight: "bold", maxWidth: 750, textAlign: "center" }}
            >
              ENDLESS PRACTICE OF ANATOMY, POSES AND MORE.
            </Typography>
          </div>
        ) : (
          <div
            style={{
              position: "absolute",
              top: 150,
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
              width: "100%",
            }}
          >
            <Typography
              variant="h5"
              component="h5"
              className="font-perpetua"
              sx={{ fontWeight: "bold", maxWidth: 500, textAlign: "center" }}
            >
              ENDLESS PRACTICE OF ANATOMY, POSES AND MORE.
            </Typography>
          </div>
        )}
        {(!token || !user) && (
          <>
            {size.screen === BreakPoint.l || size.screen === BreakPoint.xl ? (
              <div style={{ position: "absolute", top: 250, right: 120 }}>
                <div sx={{ display: "flex", flexDirection: "row" }}>
                  <Button
                    text="Join now!"
                    width={btnWidth}
                    height={40}
                    size="small"
                    sx={{ marginRight: 2, textTransform: "none" }}
                    onClick={() => navigate("/plans")}
                  />
                  <Button
                    text="Free trial!"
                    width={btnWidth}
                    height={40}
                    size="small"
                    sx={{ textTransform: "none" }}
                    onClick={() => navigate("/signup")}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  position: "absolute",
                  top: 300,
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div sx={{ display: "flex", flexDirection: "row" }}>
                  <Button
                    text="Join now!"
                    width={btnWidth}
                    height={40}
                    size="small"
                    sx={{ marginRight: 2, textTransform: "none" }}
                    onClick={() => navigate("/plans")}
                  />
                  <Button
                    text="Free trial!"
                    width={btnWidth}
                    height={40}
                    size="small"
                    sx={{ textTransform: "none" }}
                    onClick={() => navigate("/signup")}
                  />
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <Box
        sx={{
          my:
            size.screen === BreakPoint.l || size.screen === BreakPoint.xl
              ? 4
              : 2,
          px:
            size.screen === BreakPoint.l || size.screen === BreakPoint.xl
              ? 16
              : 4,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        {isExpired && <ExpiryMessage />}
        {size.screen === BreakPoint.l || size.screen === BreakPoint.xl ? (
          <h2 className="font-perpetua">CHOOSE A CATEGORY AND GET STARTED</h2>
        ) : (
          <h5 className="font-perpetua">CHOOSE A CATEGORY AND GET STARTED</h5>
        )}
        <div className="categories-wrapper">
          {categories?.map((k) => (
            <CategoryItem category={k} key={k?._id} />
          ))}
        </div>
      </Box>
    </>
  );
}

export default Home;
