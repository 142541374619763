import React, { useEffect, useState } from "react";
import useStore from "../../store/store";
import categoryService from "../../services/category";
import {
  Grid,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Box,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { Button, Text } from "../../controls";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import { CATEGORY_TYPES, SLIDESHOW_FOR, PACKAGES } from "../../utils/enums";
import Constants from "../../utils/constants";
import { useNavigate } from "react-router-dom";

const GalleryImage = ({ src, onClick }) => {
  return (
    <div style={{ position: "relative" }} className="pointer" onClick={onClick}>
      <img
        alt=""
        src={src}
        style={{
          width: 200,
          height: 200,
          objectFit: "cover",
          overflow: "hidden",
          margin: 15,
          border: "1px solid #000",
        }}
      />
    </div>
  );
};

const GalleryVideo = ({ obj }) => {
  return (
    <div className="video-box pointer" style={{ position: "relative" }}>
      <video
        controls
        controlsList="nodownload"
        width={700}
        height={400}
        style={{
          border: "1px solid #000",
          overflow: "hidden",
        }}
      >
        <source src={obj?.original} type="video/mp4" />
      </video>
      <p className="title font-segeo">{obj?.title}</p>
    </div>
  );
};

const GalleryVideoThumbnail = ({ obj, onClick }) => {
  return (
    <div
      className="video-box-thumbnail pointer"
      style={{ position: "relative" }}
      onClick={onClick}
    >
      <img
        alt=""
        src={obj.thumbnail}
        style={{
          width: 200,
          height: 200,
          objectFit: "cover",
          overflow: "hidden",
          border: "1px solid #000",
        }}
      />
      <p className="title font-segeo">{obj?.title}</p>
    </div>
  );
};

export default function Details(props) {
  const navigate = useNavigate();
  const [duration, setDuration] = useState(
    Constants.SLIDESHOW_DEFAULT_DURATION
  );
  const [slideshow, setSlideshow] = useState(false);
  const [title, setTitle] = useState("");
  const [type, setType] = useState(CATEGORY_TYPES.IMAGES);
  const [cover, setCover] = useState(null);
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);
  const [tags, setTags] = useState("");
  const [imagesForSlideshow, setImagesForSlideshow] = useState([]);
  const slideshowRef = React.createRef();
  const { token, setErrorMessage, setIsLoading, user, isExpired } = useStore(
    (state) => state
  );
  const { id } = useParams();
  const [selectedTags, setSelectedTags] = useState([]);
  const [slideNumber, setCurrentSlideNumber] = useState(0);
  const [slideshowForPictures, setSlideshowForPictures] = useState(
    SLIDESHOW_FOR.NONE
  );

  useEffect(() => {
    if (!id) return;
    reload();
  }, [id]);

  const reload = () => {
    setIsLoading(true);
    categoryService
      .getById({ categoryId: id, userId: user?._id || "" })
      .then((result) => {
        if (result.error) {
          setIsLoading(false);
          setErrorMessage(result.error);
          return;
        }

        setIsLoading(false);
        let _c = result?.data || false;
        if (!_c) return;
        setTitle(_c?.title);
        setType(_c?.type);
        setCover(_c?.cover);
        setImages(_c?.images || []);
        setVideos(_c?.videos || []);
        setTags(_c?.tags || "");

        setImagesForSlideshow(
          shuffle(
            _c?.images?.map((k) => ({
              original: k?.original,
              thumbnail: k?.thumbnail,
            }))
          )
        );
      });
  };

  const handleChange = (e, value) => {
    if (e.target.checked) {
      let t = selectedTags;
      t.push(value);
      setSelectedTags(t);
      filterImages(t);
    } else {
      let t = selectedTags.filter((t) => t !== value);
      setSelectedTags(t);
      filterImages(t);
    }
  };

  const shuffle = (array) => {
    let currentIndex = array.length,
      randomIndex;
    while (currentIndex > 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;
      [array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ];
    }

    return array;
  };

  const filterImages = (t) => {
    categoryService
      .getImagesById(token, {
        categoryId: id,
        userId: user?._id || "",
        tags: t?.join(","),
      })
      .then((result) => {
        if (result.error) {
          setIsLoading(false);
          setErrorMessage(result.error);
          return;
        }

        let _c = result?.data || false;
        if (!_c) return;
        setImages(_c || []);
        setImagesForSlideshow(
          shuffle(
            _c?.map((k) => ({
              original: k?.original,
              thumbnail: k?.thumbnail,
            }))
          )
        );
      });
  };

  const handleFullscreen = (t) => {
    if (isExpired || !user || !token || user?.package === PACKAGES.FREE) {
      navigate("/plans");
    } else {
      setImagesForSlideshow([
        {
          original: t?.original,
          thumbnail: t?.thumbnail,
        },
      ]);
      slideshowRef?.current?.toggleFullScreen();
    }
  };

  const handleScreenChange = (value) => {
    setSlideshow(value);
    if (!value) {
      filterImages(selectedTags);
      slideshowRef?.current?.pause();
      setSlideshowForPictures(SLIDESHOW_FOR.NONE);
    } else {
      slideshowRef?.current?.play();
      slideshowRef?.current?.slideToIndex(0);
    }
  };

  useEffect(() => {
    if (!slideshow) return;
    let el = document.querySelector(".image-gallery-index") || false;
    if (!el) return;
    let f = duration;
    el.innerHTML = `${f}s`;
    let interval = setInterval(() => {
      f -= 1;
      el.innerHTML = `${f}s`;
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [slideNumber, slideshow]);

  // const handleKeyDown = (e) => {
  //   if (e.key === "ArrowLeft") {
  //     let thumbnails = document.querySelectorAll(".image-gallery-thumbnail");
  //     let length = thumbnails?.length;
  //     let max = length - 1;
  //     let activeIndex;
  //     thumbnails.forEach((thumbnail, idx) => {
  //       if (thumbnail.getAttribute("class").includes("active")) {
  //         activeIndex = idx;
  //       }
  //     });
  //     let newIndex;
  //     if (activeIndex <= max) newIndex = activeIndex - 1;
  //     if (activeIndex === 0) newIndex = max;
  //     console.log("newIndex: " + newIndex);
  //     slideshowRef?.current?.slideToIndex(newIndex);
  //   } else if (e.key === "ArrowRight") {
  //     let thumbnails = document.querySelectorAll(".image-gallery-thumbnail");
  //     let length = thumbnails?.length;
  //     let max = length - 1;
  //     let activeIndex;
  //     thumbnails.forEach((thumbnail, idx) => {
  //       if (thumbnail.getAttribute("class").includes("active")) {
  //         activeIndex = idx;
  //       }
  //     });
  //     let newIndex;
  //     if (activeIndex < max) newIndex = activeIndex + 1;
  //     if (activeIndex === max) newIndex = 0;
  //     console.log("newIndex: " + newIndex);
  //     slideshowRef?.current?.slideToIndex(newIndex);
  //   }
  // };

  // useEffect(() => {
  //   document.removeEventListener("keydown", handleKeyDown, true);
  //   document.addEventListener("keydown", handleKeyDown);
  // }, []);

  return (
    <>
      <div className="category-details">
        {slideshowForPictures === SLIDESHOW_FOR.MULTIPLE ? (
          <ImageGallery
            items={imagesForSlideshow}
            showThumbnails={false}
            showBullets={false}
            showIndex={true}
            showFullscreenButton={true}
            useBrowserFullscreen={true}
            showNav={true}
            infinite={true}
            showPlayButton={false}
            autoPlay={true}
            slideDuration={300}
            swipingTransitionDuration={500}
            slideInterval={duration * 1000}
            ref={slideshowRef}
            onScreenChange={handleScreenChange}
            additionalClass={slideshow ? "" : "d-none"}
            useWindowKeyDown={true}
            onSlide={setCurrentSlideNumber}
          />
        ) : (
          <ImageGallery
            items={imagesForSlideshow}
            showThumbnails={false}
            showBullets={false}
            showIndex={false}
            showFullscreenButton={true}
            useBrowserFullscreen={true}
            showNav={false}
            infinite={false}
            showPlayButton={false}
            autoPlay={false}
            onScreenChange={handleScreenChange}
            ref={slideshowRef}
            additionalClass={slideshow ? "" : "d-none"}
          />
        )}
      </div>
      {cover && (
        <img
          src={cover}
          alt=""
          className="cover-full"
          style={{ height: Constants.GALLERY_HEIGHT }}
        />
      )}
      <Grid container sx={{ p: 2 }}>
        <Grid
          item
          xs={12}
          md={12}
          sx={{ display: "flex", justifyContent: "center" }}
        >
          <Typography
            variant="h4"
            component="h4"
            sx={{ my: 3, fontWeight: 600 }}
          >
            {title}
          </Typography>
        </Grid>
        {type === CATEGORY_TYPES.IMAGES && (
          <>
            <Grid
              item
              xs={12}
              md={8}
              style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="parent">
                {tags?.split(",").map((k) => (
                  <FormGroup key={k}>
                    <FormControlLabel
                      control={
                        <Checkbox onChange={(e) => handleChange(e, k)} />
                      }
                      label={k}
                    />
                  </FormGroup>
                ))}
              </div>
            </Grid>
            <Grid item xs={12} md={4}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Text
                  label="Seconds per image"
                  size="small"
                  style={{
                    maxWidth: 150,
                    marginRight: 10,
                  }}
                  value={duration}
                  onChange={setDuration}
                  type="number"
                  min={0}
                  textCenter={true}
                />
                <Button
                  text="Start slide show"
                  size="small"
                  width={160}
                  height={40}
                  onClick={() => {
                    if (images?.length === 0) return;
                    if (
                      isExpired ||
                      !user ||
                      !token ||
                      user?.package === PACKAGES.FREE
                    ) {
                      navigate("/plans");
                    } else {
                      setSlideshowForPictures(SLIDESHOW_FOR.MULTIPLE);
                      slideshowRef?.current?.toggleFullScreen();
                    }
                  }}
                />
              </div>
            </Grid>
          </>
        )}
      </Grid>

      {type === CATEGORY_TYPES.IMAGES && (
        <Grid container spacing={2} sx={{ p: 2, pb: 10 }}>
          {images?.length === 0 && (
            <Grid item xs={12} md={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography component="p" variant="p" style={{ color: "#000" }}>
                  No images uploaded yet.
                </Typography>
              </div>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Box
              sx={{
                px: 8,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
                flexWrap: "wrap",
              }}
            >
              {images?.map((item, idx) => (
                <GalleryImage
                  key={idx}
                  src={
                    isExpired ||
                    !user ||
                    !token ||
                    user?.package === PACKAGES.FREE
                      ? item?.thumbnail
                      : item?.original
                  }
                  onClick={() => handleFullscreen(item)}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      )}
      {type === CATEGORY_TYPES.VIDEOS && (
        <Grid container spacing={2} sx={{ p: 2, pb: 10 }}>
          {videos?.length === 0 && (
            <Grid item xs={12} md={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography component="p" variant="p" style={{ color: "#000" }}>
                  No videos uploaded yet.
                </Typography>
              </div>
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            {!token || !user || isExpired ? (
              <Box
                sx={{
                  px: 8,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {videos.map((item, idx) => (
                  <GalleryVideoThumbnail
                    key={idx}
                    obj={item}
                    onClick={() => navigate("/plans")}
                  />
                ))}
              </Box>
            ) : (
              <Box
                sx={{
                  px: 8,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                {videos.map((item, idx) => (
                  <GalleryVideo key={idx} obj={item} />
                ))}
              </Box>
            )}
          </Grid>
        </Grid>
      )}
    </>
  );
}
