import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import useStore from "../../store/store";
import { Typography, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import {
  PACKAGES,
  PAYMENT_METHODS,
  PAYMENT_TYPES,
  SUBSCRIPTION_STATUS,
} from "../../utils/enums";
import Utils from "../../utils/utils";
import PayPalCheckoutWrapper from "./PayPalCheckoutWrapper";
import PayPalSubscriptionWrapper from "./PayPalSubscriptionWrapper";
import paymentService from "../../services/payment";
import moment from "moment";
import StripeCheckout from "./StripeCheckout";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function MakePayment({
  open,
  onClose,
  onSuccess,
  price,
  plan,
  pricing,
}) {
  const [type, setType] = useState("paypal");
  const { token, setErrorMessage, setIsLoading, setSuccessMessage } = useStore(
    (state) => state
  );

  const handleSavePayment = async (details) => {
    if (plan === PACKAGES.MONTHLY || plan === PACKAGES.YEARLY) {
      setIsLoading(true);
      paymentService
        .createSubscription(token, {
          method: PAYMENT_METHODS.PAYPAL,
          plan: plan,
          amount: pricing?.[plan],
          type: PAYMENT_TYPES.SUBSCRIPTION,
          status: SUBSCRIPTION_STATUS.ACTIVE,
          subscriptionId: details.subscriptionID,
          baseSubscriptionId: details.subscriptionID,
          details: details,
          date: moment().format(),
        })
        .then((result) => {
          if (result.error) {
            setErrorMessage(result.error);
            setIsLoading(false);
            return;
          }

          setSuccessMessage("Payment completed successfully!");
          setIsLoading(false);
          onSuccess();
        });
    } else if (plan === PACKAGES.LIFETIME) {
      setIsLoading(true);
      paymentService
        .createPayment(token, {
          method: PAYMENT_METHODS.PAYPAL,
          plan: plan,
          amount: pricing?.[plan],
          type: PAYMENT_TYPES.PAYMENT,
          status: SUBSCRIPTION_STATUS.ONETIME,
          subscriptionId: "",
          baseSubscriptionId: "",
          details: details,
          date: moment().format(),
        })
        .then((result) => {
          if (result.error) {
            setErrorMessage(result.error);
            setIsLoading(false);
            return;
          }

          setSuccessMessage("Payment completed successfully!");
          setIsLoading(false);
          onSuccess();
        });
    }
  };

  return (
    <div>
      <Dialog open={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <DialogTitle>Make Payment</DialogTitle>
        <DialogContent>
          <Typography variant="div" component="div">
            You're buying <strong>{Utils.toCamelCase(plan)}</strong> package for{" "}
            <strong>${price}</strong>
            {plan === PACKAGES.MONTHLY && <> per month.</>}
            {plan === PACKAGES.YEARLY && <> per year.</>}
            {plan === PACKAGES.LIFETIME && <> for lifetime.</>}
          </Typography>
          <Typography variant="p" component="p" sx={{ mt: 2 }}>
            Choose your payment method
          </Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            row
            value={type}
            onChange={(e) => setType(e.target.value)}
            style={{ marginBottom: 20 }}
          >
            <FormControlLabel
              value={PAYMENT_METHODS.PAYPAL}
              control={<Radio />}
              label="PayPal"
            />
            <FormControlLabel
              value={PAYMENT_METHODS.STRIPE}
              control={<Radio />}
              label="Stripe"
            />
          </RadioGroup>
          {type === PAYMENT_METHODS.PAYPAL && (
            <>
              {plan === PACKAGES.LIFETIME && pricing?.[plan] && (
                <PayPalCheckoutWrapper
                  amount={pricing?.[plan]}
                  onSuccess={handleSavePayment}
                  description={`${process.env.REACT_APP_NAME} ${PACKAGES.LIFETIME} package`}
                />
              )}
              {plan === PACKAGES.MONTHLY &&
                process.env.REACT_APP_PAYPAL_SUBSCRIPTION_MONTHLY_PLAN_ID && (
                  <PayPalSubscriptionWrapper
                    paypalPlanId={
                      process.env.REACT_APP_PAYPAL_SUBSCRIPTION_MONTHLY_PLAN_ID
                    }
                    onSuccess={handleSavePayment}
                  />
                )}
              {plan === PACKAGES.YEARLY &&
                process.env.REACT_APP_PAYPAL_SUBSCRIPTION_YEARLY_PLAN_ID && (
                  <PayPalSubscriptionWrapper
                    paypalPlanId={
                      process.env.REACT_APP_PAYPAL_SUBSCRIPTION_YEARLY_PLAN_ID
                    }
                    onSuccess={handleSavePayment}
                  />
                )}
            </>
          )}
          {type === PAYMENT_METHODS.STRIPE && (
            <>
              <Elements stripe={stripePromise}>
                <StripeCheckout
                  plan={plan}
                  pricing={pricing}
                  onSuccess={onSuccess}
                  title={
                    plan === PACKAGES.LIFETIME
                      ? "Pay with Stripe"
                      : "Create Subscription"
                  }
                />
              </Elements>
            </>
          )}
        </DialogContent>

        <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
          <Button variant="contained" color="grey" onClick={onClose}>
            Cancel
          </Button>
          {/* <Button onClick={handleSubmit} variant="contained">
            Create
          </Button> */}
        </DialogActions>
      </Dialog>
    </div>
  );
}
