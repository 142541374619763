import validator from "validator";
import axios from "axios";
import { validate as uuidValidate } from "uuid";
import Constants from "./constants";

const getUrlVars = (url) => {
  var vars = {};
  url.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
    vars[key] = value;
  });
  return vars;
};

export default class Utils {
  static isValidEmail = (email) => validator.isEmail(email);
  static isValidUsername = (username) =>
    username?.length >= Constants.USERNAME_MIN_LENGTH;

  static getUrlParam = (url, parameter, defaultvalue) => {
    let urlparameter = defaultvalue;
    if (url.indexOf(parameter) > -1) {
      urlparameter = getUrlVars(url)[parameter];
    }
    return urlparameter;
  };

  static getIp = async () => {
    const ipResponse = await axios.get(`https://api.ipify.org/?format=json`);
    const ip = (ipResponse && ipResponse.data && ipResponse.data.ip) || "";
    return ip;
  };

  static formatToCurrency = (amount, currency) => {
    if (!amount) return;
    return (
      currency +
      parseFloat(amount)
        .toFixed(2)
        .replace(/\d(?=(\d{3})+\.)/g, "$&,")
        .replace(".00", "")
    );
  };

  static formatBtcToCurrency = (amount, currency) => {
    if (!amount) return;
    return parseFloat(amount).toFixed(8) + currency;
  };

  static formatToNumber = (amount) => {
    if (!amount) return;
    return parseFloat(amount)
      .toFixed(2)
      .replace(/\d(?=(\d{3})+\.)/g, "$&,")
      .replace(".00", "");
  };

  static isValidUuid = (uid) => uuidValidate(uid);

  static replaceAll = (oldString, newString, fullString) =>
    fullString.split(oldString).join(newString);

  static toCamelCase = (str) => str.charAt(0).toUpperCase() + str.slice(1);
}
