import { TextField } from "@mui/material";

function Text({
  label,
  value,
  onChange,
  min,
  max,
  textCenter = false,
  ...rest
}) {
  return (
    <TextField
      variant="outlined"
      label={label}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      fullWidth
      color="primary"
      style={{ marginTop: 10, marginBottom: 10 }}
      InputProps={{
        inputProps: {
          max: max,
          min: min,
          style: textCenter
            ? {
                textAlign: "center",
              }
            : {},
        },
      }}
      {...rest}
    ></TextField>
  );
}

export default Text;
