import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { Text } from "../../controls";
import useStore from "../../store/store";
import userService from "../../services/user";

export default function ChangeName({ open, onClose }) {
  const {
    token,
    user,
    setErrorMessage,
    setSuccessMessage,
    setIsLoading,
    setUser,
  } = useStore((state) => state);
  const [name, setName] = useState("");

  useEffect(() => {
    setName(user?.name);

    return () => {
      setName("");
    };
  }, []);

  const handleSubmit = () => {
    setIsLoading(true);
    userService.update(token, { name }).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setUser(result.data);
      setSuccessMessage("Name updated successfully!");
      setIsLoading(false);
      onClose();
    });
  };

  return (
    <div>
      <Dialog open={open} sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
        <DialogContent style={{ minWidth: 300 }}>
          <Text label="Full name" value={name} onChange={setName} />
        </DialogContent>
        <DialogActions sx={{ paddingRight: 2, paddingBottom: 2 }}>
          <Button variant="contained" color="grey" onClick={onClose}>
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained">
            Update
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
