import React, { useEffect, useState } from "react";
import messageService from "../../services/message";
import utils from "../../utils/utils";
import useStore from "../../store/store";
import { Button, Text, Error, Success, Heading } from "../../controls";
import { EmailOutlined } from "@mui/icons-material";
import { Container } from "@mui/material";

function Contact() {
  const [email, setEmail] = useState("");
  const [title, setTitle] = useState("");
  const [details, setDetails] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const { user, token, setIsLoading } = useStore((state) => state);

  useEffect(() => {
    if (token && user) {
      setEmail(user?.email);
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrorMessage("");
    setSuccessMessage("");

    if (!email || email.length === 0) {
      setErrorMessage(`Email address is required.`);
      return;
    } else {
      if (!utils.isValidEmail(email)) {
        setErrorMessage(`Please provide a valid email address.`);
        return;
      }
    }
    if (!title || title.length === 0) {
      setErrorMessage(`Title is required.`);
      return;
    }
    if (!details || details.length === 0) {
      setErrorMessage(`Details is required.`);
      return;
    }

    setIsLoading(true);
    messageService.send({ email, title, message: details }).then((result) => {
      if (result.error) {
        setErrorMessage(result.error);
        setIsLoading(false);
        return;
      }

      setErrorMessage("");
      setSuccessMessage(
        `Message sent successfully. Thank you for contacting us.`
      );
      setEmail("");
      setTitle("");
      setDetails("");
      setIsLoading(false);
    });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 10 }}>
      <Heading text="Contact Us" sx={{ mt: 10, mb: 3 }} />
      <Text label="Email address" value={email} onChange={setEmail} />
      <Text label="Title" value={title} onChange={setTitle} />
      <Text
        label="Details"
        value={details}
        onChange={setDetails}
        multiline={true}
        rows={4}
      />
      <Button
        text="Send Message"
        onClick={handleSubmit}
        sx={{ mt: 2, mb: 2 }}
        icon={<EmailOutlined />}
        disabled={
          email?.length === 0 && details?.length === 0 && title?.length === 0
        }
      />

      <Error text={errorMessage} />
      <Success text={successMessage} />
    </Container>
  );
}

export default Contact;
