import React from "react";
import { Typography } from "@mui/material";
import { WarningOutlined } from "@mui/icons-material";
import { Link } from "react-router-dom";

function ExpiryMessage({ showLink = true }) {
  return (
    <Typography
      variant="p"
      component="p"
      color="error"
      style={{ display: "flex", alignItems: "center" }}
    >
      <WarningOutlined color="error" style={{ marginRight: 8 }} />
      Your package has ended. If you want to continue using our platform, please
      sign up for one of our subscriptions. &nbsp;
      {showLink && <Link to="/plans">Checkout our plans</Link>}
    </Typography>
  );
}

export default ExpiryMessage;
