import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useStore from "../../store/store";
import {
  Grid,
  Typography,
  Table,
  TableContainer,
  Paper,
  TableBody,
  TableRow,
  TableCell,
  Chip,
  Box,
} from "@mui/material";
import {
  AccessTimeOutlined,
  TimelapseOutlined,
  Verified,
  PersonOutline,
  EmailOutlined,
  CreateOutlined,
  PersonSearchOutlined,
} from "@mui/icons-material";
import moment from "moment";
import userService from "../../services/user";
import Utils from "../../utils/utils";
import { PACKAGES } from "../../utils/enums";
import { IconButton } from "../../controls";
import ChangeName from "./ChangeName";
import ChangePassword from "./ChangePassword";
import ChangeUsername from "./ChangeUsername";

function Profile() {
  const navigate = useNavigate();
  const {
    token,
    user,
    setUser,
    setIsLoading,
    isLoggedIn,
    setErrorMessage,
    setIsExpired,
  } = useStore((state) => state);
  const [showChangeName, setShowChangeName] = useState(false);
  const [showChangeUsername, setShowChangeUsername] = useState(false);

  useEffect(() => {
    if (!isLoggedIn || !token) navigate("/login");
    reload();
  }, []);

  const reload = () => {
    setIsLoading(true);
    userService.reload(token).then((r) => {
      if (r.error) {
        setErrorMessage(r.error);
        setIsLoading(false);
        return;
      }

      if (r.data) {
        setUser(r.data);
        setIsExpired(r.data?.isExpired);
        setIsLoading(false);
      }
    });
  };

  return (
    <>
      <ChangeName
        open={showChangeName}
        onClose={() => setShowChangeName(false)}
      />
      <ChangeUsername
        open={showChangeUsername}
        onClose={() => setShowChangeUsername(false)}
      />
      <Grid container spacing={4} sx={{ p: 4 }}>
        <Grid item xs={12} md={6}>
          <Box component={Paper} sx={{ p: 4 }}>
            <Typography component="h5" variant="h5">
              Profile
            </Typography>
            <TableContainer>
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell align="right">
                      <PersonOutline />
                    </TableCell>
                    <TableCell align="left">
                      Name: {user?.name || "--"}
                      <Chip
                        label={Utils.toCamelCase(user?.package)}
                        color="success"
                        size="small"
                        style={{ marginLeft: 8 }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        icon={<CreateOutlined size="small" />}
                        onClick={() => setShowChangeName(true)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">
                      <PersonSearchOutlined />
                    </TableCell>
                    <TableCell align="left">
                      Username: {user?.username || "--"}
                    </TableCell>
                    <TableCell align="center">
                      <IconButton
                        size="small"
                        icon={<CreateOutlined size="small" />}
                        onClick={() => setShowChangeUsername(true)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">
                      <TimelapseOutlined />
                    </TableCell>
                    <TableCell align="left">
                      Package expiration:{" "}
                      {user?.package === PACKAGES.LIFETIME ? (
                        <div
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                          }}
                        >
                          Lifetime{" "}
                          <Verified
                            color="success"
                            size="small"
                            style={{ fontSize: 16, marginLeft: 8 }}
                          />
                        </div>
                      ) : (
                        <>{moment(user?.willExpireAt).format("DD MMMM YYYY")}</>
                      )}
                    </TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="right">
                      <AccessTimeOutlined />
                    </TableCell>
                    <TableCell align="left">
                      Member since:{" "}
                      {moment(user?.joined).format("DD MMMM YYYY")}
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      align="left"
                    ></TableCell>
                  </TableRow>
                  <TableRow
                    sx={{
                      "&:last-child td, &:last-child th": {
                        border: 0,
                      },
                    }}
                  >
                    <TableCell align="right">
                      <EmailOutlined />
                    </TableCell>
                    <TableCell align="left">{user?.email}</TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>

        <Grid item xs={12} md={6}>
          <Box component={Paper} sx={{ p: 4 }}>
            <ChangePassword />
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Profile;
