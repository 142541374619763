import "./assets/css/style.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/home/Home";
import useStore from "./store/store";
import Loading from "./components/Loading";
import Signup from "./pages/auth/Signup";
import Login from "./pages/auth/Login";
import ForgetPassword from "./pages/auth/ForgetPassword";
import ResetPassword from "./pages/auth/ResetPassword";
import Activate from "./pages/auth/Activate";
import Profile from "./pages/user/Profile";
import Header from "./components/Header";
import ToastError from "./components/ToastError";
import ToastSuccess from "./components/ToastSuccess";
import ToastInfo from "./components/ToastInfo";
import HeaderPublic from "./components/HeaderPublic";
import Contact from "./pages/contact/Contact";
import Details from "./pages/categories/Details";
import Plans from "./pages/plans/Plans";
import { useEffect } from "react";

function App() {
  const {
    isLoading,
    isLoggedIn,
    user,
    errorMessage,
    setErrorMessage,
    successMessage,
    setSuccessMessage,
    infoMessage,
    setInfoMessage,
  } = useStore((state) => state);

  // disable right-click on the application
  useEffect(() => {
    try {
      const handleContextmenu = (e) => e.preventDefault();
      document.addEventListener("contextmenu", handleContextmenu);
      return () =>
        document.removeEventListener("contextmenu", handleContextmenu);
    } catch (e) {}
  }, []);

  return (
    <Router>
      {isLoading === true && <Loading />}
      <ToastError
        open={errorMessage?.length > 0}
        onClose={() => setErrorMessage("")}
        body={errorMessage}
      />
      <ToastSuccess
        open={successMessage?.length > 0}
        onClose={() => setSuccessMessage("")}
        body={successMessage}
      />
      <ToastInfo
        open={infoMessage?.length > 0}
        onClose={() => setInfoMessage("")}
        body={infoMessage}
      />
      {(isLoggedIn && user && <Header />) || <HeaderPublic />}
      <Routes>
        <Route exact path="/signup" element={<Signup />} />
        <Route exact path="/login" element={<Login />} />
        <Route exact path="/fp" element={<ForgetPassword />} />
        <Route exact path="/rp" element={<ResetPassword />} />
        <Route exact path="/activate" element={<Activate />} />
        <Route exact path="/" element={<Home />} />
        <Route exact path="/category/:id" element={<Details />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/profile" element={<Profile />} />
        <Route exact path="/plans" element={<Plans />} />
      </Routes>
    </Router>
  );
}

export default App;
