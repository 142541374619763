import React, { useState } from "react";
import userService from "../../services/user";
import utils from "../../utils/utils";
import useStore from "../../store/store";
import moment from "moment";
import {
  Button,
  Text,
  Password,
  Error,
  Success,
  Heading,
  LinkButton,
} from "../../controls";
import { PersonAdd } from "@mui/icons-material";
import { Container } from "@mui/material";
import Constants from "../../utils/constants";

function Signup(props) {
  const { setIsLoading } = useStore((state) => state);
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    setErrorMessage("");
    setSuccessMessage("");

    if (!email || !password || !username) {
      setErrorMessage(`Please provide all required fields.`);
      return;
    }
    if (!utils.isValidUsername(username)) {
      setErrorMessage(
        `Username should have atleast ${Constants.USERNAME_MIN_LENGTH} characters.`
      );
      return;
    }
    if (!utils.isValidEmail(email)) {
      setErrorMessage(`Please provide a valid email address.`);
      return;
    }

    setIsLoading(true);
    userService
      .signup({
        name,
        username,
        email,
        password,
        joined: moment().format(),
      })
      .then((result) => {
        if (result.error) {
          setErrorMessage(result.error);
          setIsLoading(false);
          return;
        }

        setErrorMessage("");
        setSuccessMessage(
          `Signup successful! Please check your mailbox to activate your account.`
        );
        setIsLoading(false);
      });
  };

  return (
    <Container maxWidth="sm" sx={{ mt: 10 }}>
      <Heading text="Sign Up" sx={{ mt: 10, mb: 3 }} />
      <Text label="Full name (optional)" value={name} onChange={setName} />
      <Text label="Username" value={username} onChange={setUsername} />
      <Text label="Email address" value={email} onChange={setEmail} />
      <Password label="Password" value={password} onChange={setPassword} />
      <Button
        text="Create Account"
        onClick={handleSubmit}
        sx={{ mt: 2, mb: 2 }}
        icon={<PersonAdd />}
      />
      <LinkButton label="Already have account?" text="Sign In" to="/login" />
      <Error text={errorMessage} />
      <Success text={successMessage} />
    </Container>
  );
}

export default Signup;
