export default class Constants {
  static TOKEN_NAME = `x-auth`;
  static PRIMARY = "#6F42C1";
  static INFO = "#0288D1";
  static PRICING = {
    trial: 5,
    monthly: 4.99,
    yearly: 45.99,
    lifetime: 100,
  };
  static DURATION = 40;
  static CURRENCY = "USD";
  static MAX_PHOTOS = 5;
  static GALLERY_HEIGHT = 500;
  static SLIDESHOW_DEFAULT_DURATION = 60;
  static USERNAME_MIN_LENGTH = 5;
}
